* {
    --color-lilac: #BDB2C3;
    --color-lilac-dark: #545166;
    --color-lilac-dark: #252428;
    --color-white: #FFFFFF;
    --color-gray: #46454C;
    --color-gray-light: #E6E6E6;
    --color-panel: rgba(255, 255, 255, 0.8);
    --color-gray-a2: #00000006;
    --color-gray-a5: #0000001f;
    --color-black-a2: rgba(0, 0, 0, .1);
    --color-gray-semi: #636363;
    --color-black: #252428;
    --color-blue: #6FC3FF;
    --color-green: #48D04C;
    --color-green-dark: #37C33B;
}
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap";
* {
  --color-lilac: #bdb2c3;
  --color-lilac-dark: #252428;
  --color-white: #fff;
  --color-gray: #46454c;
  --color-gray-light: #e6e6e6;
  --color-panel: #fffc;
  --color-gray-a2: #00000006;
  --color-gray-a5: #0000001f;
  --color-black-a2: #0000001a;
  --color-gray-semi: #636363;
  --color-black: #252428;
  --color-blue: #6fc3ff;
  --color-green: #48d04c;
  --color-green-dark: #37c33b;
}

.fancybox__container {
  pointer-events: auto;
}

@font-face {
  font-family: Main;
  src: url("main.6ab45b66.otf");
}

.lv-booking-module {
  z-index: 10002;
  border: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#booking-module-widjet {
  backdrop-filter: blur(20px);
  border: none;
  border-radius: 24px;
  width: 846px;
  height: 82px;
}

#booking-module-calendar {
  z-index: 10001;
  -webkit-appearance: none;
  border: none;
  border-radius: 4px;
  width: calc(100vw - 32px);
  max-width: 995px;
  height: calc(61.5vw - 19.68px);
  max-height: 612px;
  position: absolute;
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

#booking-module-rooms {
  z-index: 10001;
  -webkit-appearance: none;
  border: none;
  border-radius: 4px;
  transition: top .2s;
  position: absolute;
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

@media (width <= 816px) {
  #booking-module-calendar {
    width: calc(50vw + 384px);
    height: calc(30.75vw + 236.16px);
  }
}

@media (width <= 1024px) {
  #booking-module-widjet {
    width: 781px;
  }
}

@media (width <= 768px) {
  #booking-module-widjet {
    width: 252px;
    height: 268px;
  }

  #booking-module-calendar, #booking-module-rooms {
    border-radius: 0;
    width: 100dvw;
    max-width: none;
    height: 100dvh;
    max-height: none;
    position: fixed;
  }

  #booking-module-rooms-wrapper {
    z-index: 10001;
    background-color: #00000080;
    place-items: center;
    width: 100dvw;
    height: 100dvh;
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
  }

  #booking-module-rooms-wrapper > #booking-module-rooms {
    position: relative;
    width: auto !important;
  }
}
/*# sourceMappingURL=app.892587b3.css.map */
